<template>
    <el-dialog
        :title="tips"
        :visible.sync="isDialog"
        :close-on-click-modal="false">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="角色名称" prop="name">
                <el-input v-model="ruleForm.name" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="角色标识" prop="tips">
                <el-input v-model="ruleForm.tips" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="角色描述" prop="desc">
                <el-input type="textarea" v-model="ruleForm.desc" placeholder="请输入角色描述"></el-input>
            </el-form-item>
             <el-form-item label="角色类型" prop="type">
                <el-select v-model="ruleForm.type" clearable placeholder="请选择角色类型" @change="changeTypeSelectEvent">
                    <el-option v-for="item in typeList" :label="item.label" :value="item.value" :key="item.value"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="数据权限" prop="permissions">
                <el-select v-model="ruleForm.permissions" clearable placeholder="请选择数据权限" @change="changeSelectEvent">
                    <el-option v-for="item in options" :label="item.label" :value="item.value" :key="item.value"></el-option>
                </el-select>
            </el-form-item> -->
            <!-- <el-form-item>
              <el-tree
                :data="data"
                show-checkbox
                node-key="id"
                :props="defaultProps"
                @check-change="handleCheckChange"
                v-if="ruleForm.permissions == 1">
              </el-tree>
            </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="saveRole">保存</el-button>
            <el-button @click="isDialog = false">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { rolemanagerAPI } from 'liankong-ui-api' 

  export default {
    props:{
      tips: {
        type: String
      }
    },
    data() {
      return {
        isDialog: false,
        ruleForm: {
            name: '',
            tips: '',
            desc: '',
            type: ''
        },
        rules: {
          name: [
            { required: true, message: '请输入角色名称', trigger: 'blur' }
          ],
          tips: [
            { required: true, message: '请选择角色标识', trigger: 'blur' }
          ],
          type: [
            { required: true, message: '请选择角色类型', trigger: 'change' }
          ]
        },
        options: [{
          value: '0',
          label: '全部'
        }, {
          value: '1',
          label: '自定义'
        }, {
          value: '2',
          label: '本级及子级'
        }, {
          value: '3',
          label: '本级'
        }],
        typeList: [{
          value: '1',
          label: '系统角色'
        },{
          value: '5',
          label: '军衔制'
        },{
          value: '6',
          label: '密级角色'
        }],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        editInfo: {}, // 当前点击项的信息
      };
    },
    methods: {
      init(flag, editInfo){
        this.$refs['ruleForm'].resetFields();
        if(flag){ // 编辑
          this.editInfo = editInfo;
          this.ruleForm.name = editInfo.name;
          this.ruleForm.tips = editInfo.code;
          this.ruleForm.desc = editInfo.description;
          this.ruleForm.type = String(editInfo.type)
        } else {
          this.ruleForm = {
            name: '',
            tips: '',
            desc: '',
            type: ''
          }
        }
      },
      changeTypeSelectEvent(val){
        this.ruleForm.type = val;
      },
      // 保存
      saveRole(){
         this.$refs['ruleForm'].validate((valid) => {
              if (valid) {
                if(this.tips == '新增') {
                  let params = {
                      "code": this.ruleForm.tips,
                      "description": this.ruleForm.desc,
                      "name": this.ruleForm.name,
                      "scope": "",
                      "state": "0",
                      "type": this.ruleForm.type,
                      "parentId": -1
                    }
                  rolemanagerAPI.addRoles(params).then(res=>{
                    if(res.code == '0') {
                       this.$message({
                          message: '添加成功',
                          type: 'success'
                      });
                      this.isDialog = false;
                      this.$parent.formInline.pageNum = 1;
                      this.$parent.onSearchEvent()
                    } else {
                        this.$message.error(res.message);
                        this.isDialog = false;
                    }
                  }).catch(error=>{
                    this.$message.error(error.message);
                  })
                } else {
                  let editParams = {
                      "id": this.editInfo.id,
                      "code": this.ruleForm.tips,
                      "description": this.ruleForm.desc,
                      "name": this.ruleForm.name,
                      "scope": "",
                      "state": "0",
                      "type": this.ruleForm.type,
                      "parentId": -1
                  }
                  rolemanagerAPI.editRoles(editParams).then(res=>{
                      if(res.code == '0') {
                          this.$message({
                              message: '修改成功',
                              type: 'success'
                          });
                          this.isDialog = false;
                          this.$parent.formInline.pageNum = 1;
                          this.$parent.onSearchEvent();
                      } else if(res.code == '1'){
                          this.$message.error(res.message);
                          this.isDialog = false;
                      }
                  }).catch(error=>{
                    this.$message.error(error.message);
                  })
                }
              }
         })


      }
    }
  };
</script>