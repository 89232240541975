<template>
    <el-dialog
    title="分配权限"
    :visible.sync="assignperDialog"
    :close-on-click-modal="false"
    @close="cancleEvnet">
        <el-tree
        ref="trees"
        :data="roleTreeData"
        show-checkbox
        node-key="id"
        :default-expand-all="true"
        :default-checked-keys="defaultCheckedKey"
        :props="defaultProps"
        @check-change="handleCheckChange">
        </el-tree>

        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="updateEvent">更新</el-button>
            <el-button @click="cancleEvnet">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { rolemanagerAPI } from 'liankong-ui-api' 

  export default {
    data() {
      return {
        assignperDialog: false,
        roleTreeData: [],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
        defaultCheckedKey: [], // 默认选中的key
        rowInfo: {}
      };
    },
    methods: {
      treeInit(row){
        this.rowInfo = row;
        rolemanagerAPI.getTreeRoleList(false).then(res=>{
          if(res.code == '0') {
            this.roleTreeData = this.resetTreeFormat(res.data);
            this.dataCheckedEvent(row)
          }
        }).catch(error=>{
          this.$message.error(error.message);
        })
      },
      // 数据处理
      resetTreeFormat(arr){
        arr.forEach(item=>{
            item.label = item.name
            if(item.children &&item.children.length > 0 ){
                this.resetTreeFormat(item.children)
            }
        })
        return arr
      },
      // 默认数据选中
      dataCheckedEvent(row){
        rolemanagerAPI.treeRoleInitChecked(row.id).then(res=>{
          if(res.code == '0') {
            if(res.data.length > 0) {
                this.defaultCheckedKey = this.resolveAllEunuchNodeId(this.roleTreeData,res.data,[]);
            } else {
               this.defaultCheckedKey = []
            }
          }
        }).catch(error=>{
          this.$message.error(error.message);
        })
      },
       /**
       * 解析出所有的太监节点id
       * @param json 待解析的json串
       * @param idArr 原始节点数组
       * @param temp 临时存放节点id的数组
       * @return 太监节点id数组
       */
      resolveAllEunuchNodeId(json, idArr, temp) {
           for (let i = 0; i < json.length; i++) {
            const item = json[i]
            // 存在子节点，递归遍历;不存在子节点，将json的id添加到临时数组中
            if (item.children && item.children.length !== 0) {
              this.resolveAllEunuchNodeId(item.children, idArr, temp)
            } else {
              temp.push(idArr.filter(id => id == item.id))
            }
          }
          return temp
      },
      // tree选中事件
      handleCheckChange(data, checked, indeterminate) {
      },
      // 更新
      updateEvent(){
        let menuIds = this.$refs.trees.getCheckedKeys().join(',').concat(',').concat(this.$refs.trees.getHalfCheckedKeys().join(','));

        let updateParams = {
          "menuIds": menuIds,
          "roleId": this.rowInfo.id
        }
        rolemanagerAPI.updateTreeRoleMenu(updateParams).then(res=>{
          if(res.code == '0') {
              this.$message({
                message: '更新成功',
                type: 'success'
            });
            this.assignperDialog = false;
            this.$parent.onSearchEvent();
          } else {
              this.$message.error(res.message);
              this.assignperDialog = false;
          }
        })
      },
      // 取消、X
      cancleEvnet(){
        this.assignperDialog = false;
        this.$refs.trees.setCheckedKeys([]);
      }


    }
  };
</script>
<style lang="less" scoped>

</style>