<template>
    <div>
        <el-form :inline="true" :model="formInline" ref="formInline" class="demo-form-inline form-box">
            <el-form-item label="角色名称">
                <el-input v-model="formInline.name" placeholder="角色名称"></el-input>
            </el-form-item>
             <!-- <el-form-item label="角色标识">
                <el-input v-model="formInline.roleTips" placeholder="角色标识"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label="数据权限">
                <el-select v-model="formInline.roleRegion" placeholder="数据权限">
                    <el-option label="全部" value="0"></el-option>
                    <el-option label="自定义" value="1"></el-option>
                    <el-option label="本级及子级" value="2"></el-option>
                    <el-option label="本级" value="3"></el-option>
                </el-select>
            </el-form-item> -->
             <el-form-item label="角色类型">
                <el-select v-model="formInline.type" placeholder="角色类型">
                    <el-option label="全部" value=" "></el-option>
                    <el-option label="系统角色" value="1"></el-option>
                    <el-option label="军衔制" value="5"></el-option>
                    <el-option label="密级角色" value="6"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="onSearchEvent">搜索</el-button>
                <el-button  icon="el-icon-delete" @click="resetForm">清空</el-button>
            </el-form-item>
        </el-form>
        <el-button type="primary" icon="el-icon-edit" style="margin: 0 20px;" v-if="newObj.sys_role_add" @click="addRoleEvent('新增', false)">添加</el-button>

       <div style="margin: 20px 20px 0 20px;padding-bottom:20px;">
            <el-table
            :data="tableData"
            border
            style="width:100%;">
                <el-table-column
                type="index"
                label="序号"
                align="center"
                width="150">
                </el-table-column>
                <el-table-column
                prop="name"
                label="角色名称"
                align="center"
                width="165">
                </el-table-column>
                <el-table-column
                prop="code"
                label="角色标识"
                align="center"
                width="200">
                </el-table-column>
                <el-table-column
                prop="description"
                label="角色描述"
                align="center"
                width="200">
                </el-table-column>
                <!-- <el-table-column
                prop="address"
                label="数据权限"
                align="center"
                width="150">
                </el-table-column> -->
                <el-table-column
                label="创建时间"
                align="center"
                width="245">
                    <template slot-scope="scope">
                        {{scope.row.createDate |date}}
                    </template>
                </el-table-column>
                <el-table-column
                label="操作"
                align="center"
                >
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="lookDialogEvent(scope.row)">
                        <i class="el-icon-view"></i>
                        查看
                    </el-button>
                    <el-button type="text" size="small" v-if="newObj.sys_role_edit" @click="addRoleEvent('编辑', true, scope.row)">
                        <i class="el-icon-edit"></i>
                        编辑
                    </el-button>
                    <el-button type="text" size="small" v-if="newObj.sys_role_del" @click="removeEvent(scope.row)">
                        <i class="el-icon-delete"></i>
                        删除
                    </el-button>
                    <el-button type="text" size="small"  v-if="newObj.sys_role_perm" @click="assignPerDialog(scope.row)">
                        <i class="el-icon-plus"></i>
                        权限
                    </el-button>
                </template>
                </el-table-column>
            </el-table>
       </div>

        <el-pagination
        @current-change="handleCurrentChange"
        :current-page="formInline.pageNum"
        :page-size="formInline.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <roles-item ref='rolesitem' :tips='tips'></roles-item>
        <assign-perssion ref='assignper'></assign-perssion>
        <look-roles ref='lookroles' :lookRow="lookRow"></look-roles>

    </div>
</template>
<script>
import RolesItem from './RolesItem.vue'
import AssignPerssion from './AssignPerssion.vue'
import LookRoles from './LookRoles.vue'
import { rolemanagerAPI } from 'liankong-ui-api' 

  export default {
    data() {
      return {
        total: 0,
        formInline: {
          name: '',
        //   roleTips: '',
        //   roleRegion: '',
          pageNum: 1,
          pageSize: 10,
          type: ''
        },
        tableData: [],
        lookRow: {}, // 查看的数据
        tips: '',
        newObj: {}
      }
    },
    components:{
        RolesItem,
        AssignPerssion,
        LookRoles
    },
    computed:{
        roles(){
            return this.$store.state.login.roles || []
        }
    },
    created(){

        this.onSearchEvent()

        this.roles.forEach(roItem=>{
            this.newObj[roItem.authority] = roItem.authority
        })
    },
    methods: {
        // 查询
        onSearchEvent() {
            rolemanagerAPI.getRolesList(this.formInline).then(res=>{
                 if(res.code == '0') {
                    this.total = res.total;
                    this.tableData = res.data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch(error=>{
                this.$message.error(error.message);
            })
        },
        // 添加、编辑
        addRoleEvent(tips,flag, editInfo){
            this.$refs.rolesitem.isDialog = true;
            this.tips = tips;
            this.$nextTick(()=>{
                this.$refs.rolesitem.init(flag,editInfo)
            })
        },
        // 权限
        assignPerDialog(row){
            this.$refs.assignper.assignperDialog = true;
            this.$nextTick(()=>{
                this.$refs.assignper.treeInit(row)
            })
        },
        // 查看
        lookDialogEvent(row){
            this.$refs.lookroles.lookDialogVisible = true;
            this.lookRow = row;
        },
        // 删除
        removeEvent(row){
            this.$confirm('是否确认删除', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                rolemanagerAPI.deleteRoles(row.id).then(res=>{
                     if(res.code == '0'){
                        this.onSearchEvent();
                        this.$message.success('删除成功')
                    } else if(res.code == '1'){
                        this.$message.error(res.message)
                    }
                }).catch(error=>{
                    this.$message.error(error.message);
                })
            })
        },
        resetForm() {
            this.formInline = {
                name: '',
                //   roleTips: '',
                //   roleRegion: '',
                pageNum: 1,
                pageSize: 10,
                type: ''
            }

            this.onSearchEvent()

        },
        handleCurrentChange(val) {

            this.formInline.pageNum = val;
            this.onSearchEvent();
        }
    }
  }
</script>
<style lang="less" scoped>
.form-box {
    padding: 20px;
    .el-form-item {
        margin-right: 22px;
    }
}
.el-pagination {
    padding: 5px 20px 20px 20px;
}
</style>