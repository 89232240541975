<template>
    <el-dialog
    title="查看"
    :visible.sync="lookDialogVisible"
    :close-on-click-modal="false">
       <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="角色名称：">
                <span>{{lookRow.name}}</span>
            </el-form-item>
            <el-form-item label="角色标识：">
               <span>{{lookRow.code}}</span>
            </el-form-item>
            <el-form-item label="角色描述：">
                <span>{{lookRow.description}}</span>
            </el-form-item>
            <!-- <el-form-item label="数据权限：">
               <span>{{lookRow.address}}</span>
            </el-form-item> -->
            <el-form-item label="创建时间：">
               <span>{{lookRow.createDate | date}}</span>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>
<script>
export default {
    props:{
        lookRow:{
            typeof: Object
        }
    },
    data(){
        return{
            lookDialogVisible: false,
        }
    }
}
</script>